import { dateTime } from '@grafana/data';
import $ from 'jquery';
import kbn from 'grafana/app/core/utils/kbn';
import _, { isNumber } from 'lodash';
import { GetColorForValue, GetColorIndexForValue, StringToJsRegex } from './Utils';
import { getBackendSrv, getDataSourceSrv } from '@grafana/runtime';
import 'datatables.net';
import 'mark.js';
import 'datatables.mark.js';
import 'datatables.net-buttons';
import 'datatables.net-select';
import moment from 'moment';

export class DatatableRenderer {
  formatters: any;
  colorState: any;
  panel: any;
  table: any;
  isUtc: boolean;
  sanitize: any;
  timeSrv: any;

  // from app/core/constants
  GRID_CELL_HEIGHT = 30;
  // from inspect
  TITLE_LINE_HEIGHT = 28;
  dataSourceSrv: any;
  backendSrv: any;

  constructor(panel: any, table: any, isUtc: boolean, sanitize: any, timeSrv: any) {
    this.formatters = [];
    this.colorState = {};
    this.panel = panel;
    this.table = table;
    this.isUtc = isUtc;
    this.sanitize = sanitize;
    this.timeSrv = timeSrv;
    this.dataSourceSrv = getDataSourceSrv();
    this.backendSrv = getBackendSrv();

    // @ts-ignore
    $("select").select2({
      tags: "true",
      placeholder: "Select an option",
      allowClear: true
    });
  }

  /**
   * Formats a cell
   * @param v value
   * @param style style obj
   * @param column column
   * @param rowIndex The row index
   * @return any formatted data
   */
  defaultCellFormatter(v: any, style: any, column: any, rowIndex: number) {
    if (v === null || v === void 0 || v === undefined || column === null) {
      return '';
    }
    if (_.isArray(v)) {
      v = v.join(', ');
    }
    v = String(v);

    // coerce style to an object
    if (typeof style === 'undefined' || typeof style !== 'object') {
      style = {};
    }
    let cellTemplate = style.url;

    if (typeof style.splitPattern === 'undefined' || style.splitPattern === '') {
      style.splitPattern = '/ /';
    }

    const regex = StringToJsRegex(String(style.splitPattern));
    const values = v.split(regex);
    if (typeof cellTemplate !== 'undefined') {
      // replace $__from/$__to/$__
      cellTemplate = this.replaceTimeMacros(cellTemplate);
      // Replace $__cell with this cell's content.
      cellTemplate = cellTemplate.replace(/\$__cell\b/, v);
      values.map((val: any, i: any) => (cellTemplate = cellTemplate.replace(`$__pattern_${i}`, val)));
    }

    if (style && style.sanitize) {
      return this.sanitize(v);
    } else if (style && style.link && cellTemplate && column.text === style.column) {
      const matches = /\$__cell_(\d+)/g.exec(cellTemplate);
      // start with the template
      let linkValue = cellTemplate;
      if (matches) {
        // index zero is the whole string
        for (let matchIndex = 1; matchIndex < matches.length; matchIndex++) {
          //console.log(`rowIndex: ${rowIndex} matchIndex: ${matchIndex}`);
          const matchedCellNumber = parseInt(matches[matchIndex], 10);
          if (!isNaN(matchedCellNumber)) {
            const matchedCellContent = this.table.rows[rowIndex][matchedCellNumber];
            //console.log(`matchedCellNumber: ${matchedCellNumber} matchedCellContent: ${matchedCellContent}`);
            linkValue = linkValue.replace(`$__cell_${matchedCellNumber}`, matchedCellContent);
          }
        }
        const valueFormatter = kbn.valueFormats[column.unit || style.unit];
        if (style && style.decimals) {
          v = valueFormatter(v, style.decimals, null);
        } else {
          v = valueFormatter(v);
        }
        return '<a href="' + linkValue + '" target="_blank">' + v + '</a>';
      } else {
        const linkValue = cellTemplate.replace(/\{\}|\$__cell_\d*/g, v);
        return '<a href="' + linkValue + '" target="_blank">' + v + '</a>';
      }
      return _.escape(v);
    } else if (style && style.link) {
      return '<a href="' + v + '" target="_blank">' + v + '</a>';
    } else {
      return _.escape(v);
    }
  }

  // Similar to DataLinks, this replaces the value of the panel time ranges for use in url params
  replaceTimeMacros(content: string) {
    let newContent = content;
    if (content.match(/\$__from/g)) {
      // replace all occurences
      newContent = newContent.replace('$__from', this.timeSrv.time.from);
    }
    if (content.match(/\$__to/g)) {
      // replace all occurences
      newContent = newContent.replace('$__to', this.timeSrv.time.to);
    }
    if (content.match(/\$__keepTime/g)) {
      // replace all occurences
      const keepTime = `from=${this.timeSrv.time.from}&to=${this.timeSrv.time.to}`;
      newContent = newContent.replace('$__keepTime', keepTime);
    }
    return newContent;
  }
  /**
   * [createColumnFormatter description]
   * @param  {[type]} style  [description]
   * @param  {[type]} column [description]
   * @return {[type]}        [description]
   */
  createColumnFormatter(style: any, column: any) {
    if (!style) {
      return this.defaultCellFormatter;
    }
    if (style.type === 'hidden') {
      return (v: any, rIndex: number) => {
        return null;
      };
    }
    if (style.type === 'date') {
      return (v: any, rIndex: number) => {
        if (v === undefined || v === null) {
          return '-';
        }

        if (_.isArray(v)) {
          v = v[0];
        }

        // if is an epoch (numeric string and len > 12)
        if (_.isString(v) && !isNaN(v as any) && v.length > 12) {
          v = parseInt(v, 10);
        }

        let date = dateTime(v);

        if (this.isUtc) {
          date = date.utc();
        }
        const fmt = date.format(style.dateFormat);
        return fmt;
      };
    }
    if (style.type === 'number') {
      const valueFormatter = kbn.valueFormats[column.unit || style.unit];
      return (v: any, rIndex: number) => {
        if (v === null || v === void 0) {
          return '-';
        }
        if (_.isString(v)) {
          return this.defaultCellFormatter(v, style, column, rIndex);
        }
        if (style.colorMode) {
          this.colorState[style.colorMode] = GetColorForValue(v, style);
        }
        return valueFormatter(v, style.decimals, null);
      };
    }
    if (style.type === 'string') {
      return (v: any, rIndex: number) => {
        if (_.isArray(v)) {
          v = v.join(', ');
        }

        const mappingType = style.mappingType || 0;

        if (mappingType === 1 && style.valueMaps) {
          for (let i = 0; i < style.valueMaps.length; i++) {
            const map = style.valueMaps[i];
            if (v === null) {
              if (map.value === 'null') {
                return map.text;
              }
              continue;
            }

            // Allow both numeric and string values to be mapped
            if ((!_.isString(v) && Number(map.value) === Number(v)) || map.value === v) {
              return this.defaultCellFormatter(map.text, style, column, rIndex);
            }
          }
        }

        if (mappingType === 2 && style.rangeMaps) {
          for (let i = 0; i < style.rangeMaps.length; i++) {
            const map = style.rangeMaps[i];
            if (v === null) {
              if (map.from === 'null' && map.to === 'null') {
                return map.text;
              }
              continue;
            }

            if (Number(map.from) <= Number(v) && Number(map.to) >= Number(v)) {
              return this.defaultCellFormatter(map.text, style, column, rIndex);
            }
          }
        }

        if (v === null || v === void 0) {
          return '-';
        }
        return this.defaultCellFormatter(v, style, column, rIndex);
      };
    }
    return (v: any, rIndex: number) => {
      return this.defaultCellFormatter(v, style, column, rIndex);
    };
  }

  /**
   * [formatColumnValue description]
   * @param  {[type]} colIndex [description]
   * @param  {[type]} rowIndex [description]
   * @param  {[type]} value    [description]
   * @return {[type]}          [description]
   */
  formatColumnValue(colIndex: any, rowIndex: any, value: any) {
    if (!this.formatters[colIndex]) {
      for (let i = 0; i < this.panel.styles.length; i++) {
        const style = this.panel.styles[i];
        const column = this.table.columns[colIndex];
        const regex = StringToJsRegex(style.pattern);
        if (column.text.match(regex)) {
          this.formatters[colIndex] = this.createColumnFormatter(style, column);
        }
      }
    }
    if (!this.formatters[colIndex]) {
      this.formatters[colIndex] = this.defaultCellFormatter;
    }
    let v = this.formatters[colIndex](value, rowIndex);
    if (/\$__cell_\d+/.exec(v)) {
      for (let i = this.table.columns.length - 1; i >= 0; i--) {
        v = v.replace(`$__cell_${i}`, this.table.rows[rowIndex][i]);
      }
    }
    return v;
  }

  /**
   * [generateFormattedData description]
   * @param  {[type]} rowData [description]
   * @return {[type]}         [description]
   */
  generateFormattedData(rowData: any) {
    const formattedRowData = [];
    for (let y = 0; y < rowData.length; y++) {
      const row = this.table.rows[y];
      const cellData = [];
      for (let i = 0; i < this.table.columns.length; i++) {
        let value = this.formatColumnValue(i, y, row[i]);
        if (value === undefined || value === null) {
          this.table.columns[i].hidden = true;
        }
        if (value === null) {
          value = row[i];
        }
        const record = {
          display: value,
          raw: row[i],
          _: row[i],
        };
        cellData.push(record);
      }
      if (this.panel.rowNumbersEnabled) {
        cellData.unshift('rowCounter');
      }
      formattedRowData.push(cellData);
    }
    return formattedRowData;
  }

  getStyleForColumn(columnNumber: any) {
    let colStyle = null;
    for (let i = 0; i < this.panel.styles.length; i++) {
      const style = this.panel.styles[i];
      const column = this.table.columns[columnNumber];
      if (column === undefined) {
        break;
      }
      const regex = StringToJsRegex(style.pattern);
      if (column.text.match(regex)) {
        colStyle = style;
        break;
      }
    }
    return colStyle;
  }

  getColumnRenderStyle(data: any, row: any) {
    let colStyle = data;
    const _this = this;
    for (let i = 0; i < this.panel.styles.length; i++) {
      if (this.panel.styles[i].pattern === row) {
        if(this.panel.styles[i].type === 'date'){
          colStyle = data ? moment.unix(data).format(_this.panel.styles[i].dateFormat) : '';
        }
      }
    }
    return colStyle;
  }

  getCellColors(colorState: any, columnNumber: any, cellData: any) {
    if (cellData === null || cellData === undefined) {
      return null;
    }
    const items = cellData.split(/([^0-9.,]+)/);
    // only color cell if the content is a number?
    let bgColor = null;
    let bgColorIndex = null;
    let color = null;
    let colorIndex = null;
    let colStyle = null;
    let value = null;
    // check if the content has a numeric value after the split
    if (!isNaN(items[0])) {
      // run value through threshold function
      value = parseFloat(items[0].replace(',', '.'));
      colStyle = this.getStyleForColumn(columnNumber);
    }
    if (colStyle !== null && colStyle.colorMode != null) {
      // check color for either cell or row
      if (colorState.cell || colorState.row || colorState.rowcolumn) {
        // bgColor = _this.colorState.cell;
        bgColor = GetColorForValue(value, colStyle);
        bgColorIndex = GetColorIndexForValue(value, colStyle);
        color = 'white';
      }
      // just the value color is set
      if (colorState.value) {
        //color = _this.colorState.value;
        color = GetColorForValue(value, colStyle);
        colorIndex = GetColorIndexForValue(value, colStyle);
      }
    }
    return {
      bgColor: bgColor,
      bgColorIndex: bgColorIndex,
      color: color,
      colorIndex: colorIndex,
    };
  }

  getColumnAlias(columnName: any) {
    // default to the columnName
    let columnAlias = columnName;
    if (this.panel.columnAliases !== undefined) {
      for (let i = 0; i < this.panel.columnAliases.length; i++) {
        if (this.panel.columnAliases[i].name === columnName) {
          columnAlias = this.panel.columnAliases[i].alias;
          break;
        }
      }
    }
    return columnAlias;
  }

  getColumnWidthHint(columnName: any) {
    // default to the columnName
    let columnWidth = '';
    if (this.panel.columnWidthHints !== undefined) {
      for (let i = 0; i < this.panel.columnWidthHints.length; i++) {
        if (this.panel.columnWidthHints[i].name === columnName) {
          columnWidth = this.panel.columnWidthHints[i].width;
          break;
        }
      }
    }
    return columnWidth;
  }

  getDataForTenantId(panelid: string){
    const _this = this;
    var uid = _this.panel.targets[0].datasource.uid;
    var id = _this.dataSourceSrv.datasources[uid].id;
    let url = "/api/datasources/" + id + "/resources/tenants";
    const select_filter = $('#filter-datatable-panel-table-' + panelid + ' #tenantid');

    $.get(url, function(data, status){
      select_filter.empty().append('<option></option>');
        data.map(
          (item: any) => {
            select_filter.append('<option value="' + item.id + '">' + item.name + '</option>');
          }
          );
        });
  }

  getDataForProgramId(panelid: string) {
    const _this = this;
    var uid = _this.panel.targets[0].datasource.uid;
    var id = _this.dataSourceSrv.datasources[uid].id;
    let url = "/api/datasources/" + id + "/resources/programs";
    const select_tenant = $('#filter-datatable-panel-table-' + panelid + ' #tenantid');
    const tenantid = select_tenant.val();
    if(tenantid){
        url += "?tenantid=" + tenantid;
    }
    const select_filter = $('#filter-datatable-panel-table-' + panelid + ' #programid');

    $.get(url, function(data, status){
      select_filter.empty().append('<option></option>');
        data.map(
            (item: any) => {
                select_filter.append('<option value="' + item.id + '">' + item.name + '</option>');
            }
        );
    });
  }

  getDataForOrganisationPositionId(panelid: string) {
    const _this = this;
    var uid = _this.panel.targets[0].datasource.uid;
    var id = _this.dataSourceSrv.datasources[uid].id;
    let url = "/api/datasources/" + id + "/resources/organisationpositions";
    const select_tenant = $('#filter-datatable-panel-table-' + panelid + ' #tenantid');
    const tenantid = select_tenant.val().join(',');
    if (tenantid) {
      url += "?tenantid=" + tenantid;
    }
    const select_filter = $('#filter-datatable-panel-table-' + panelid + ' #grupoid');

    $.get(url, function(data, status){
        select_filter.empty().append('<option></option>');
        data.map(
            (item: any) => {
                select_filter.append('<option value="' + item.id + '">' + item.name + '</option>');
            }
        );
    });
  }

  getDataForDepartamentoId(panelid: string) {
    const _this = this;
    var uid = _this.panel.targets[0].datasource.uid;
    var id = _this.dataSourceSrv.datasources[uid].id;
    let url = "/api/datasources/" + id + "/resources/organisationdepartments";
    const select_tenant = $('#filter-datatable-panel-table-' + panelid + ' #tenantid');
    const tenantid = select_tenant.val().join(',');
    if (tenantid) {
        url += "?tenantid=" + tenantid;
    }
    const select_filter = $('#filter-datatable-panel-table-' + panelid + ' #departamentoid');

    $.get(url, function(data, status){
        select_filter.empty().append('<option></option>');
        data.map(
            (item: any) => {
                select_filter.append('<option value="' + item.id + '">' + item.name + '</option>');
            }
        );
    });
  }

  getDataForCursosId(panelid: string) {
    const _this = this;
    var uid = _this.panel.targets[0].datasource.uid;
    var id = _this.dataSourceSrv.datasources[uid].id;
    let url = "/api/datasources/" + id + "/resources/courses";
    const select_filter = $('#filter-datatable-panel-table-' + panelid + ' #courseid');

    $.get(url, function(data, status){
        select_filter.empty().append('<option></option>');
        data.map(
            (item: any) => {
                select_filter.append('<option value="' + item.id + '">' + item.name + '</option>');
            }
        );
    });
  }

  getDataForSelect(panelid: string) {
    this.getDataForTenantId(panelid);
    this.getDataForProgramId(panelid);
    this.getDataForOrganisationPositionId(panelid);
    this.getDataForDepartamentoId(panelid);
    this.getDataForCursosId(panelid);
    this.refreshDataTable(panelid)
  }

  refreshDataTable(panelId: string) {
    const _this = this;
    const tableHolderId = '#datatable-panel-table-' + panelId;
    const select_department = $('#filter-datatable-panel-table-' + panelId + ' #departamentoid');
    const select_course = $('#filter-datatable-panel-table-' + panelId + ' #courseid');
    const select_grupo = $('#filter-datatable-panel-table-' + panelId + ' #grupoid');
    const select_tenant = $('#filter-datatable-panel-table-' + panelId + ' #tenantid');
    const select_program = $('#filter-datatable-panel-table-' + panelId + ' #programid');
    const select_fechadesde = $('#filter-datatable-panel-table-' + panelId + ' #desde');
    const select_fechahasta = $('#filter-datatable-panel-table-' + panelId + ' #hasta');
    const select_suspended = $('#filter-datatable-panel-table-' + panelId + ' #suspended');
    const select_estado_grupo = $('#filter-datatable-panel-table-' + panelId + ' #estado_grupo');
    const input_cedula = $('#filter-datatable-panel-table-' + panelId + ' #cedula');
    const btn = $('#filter-datatable-panel-table-' + panelId + ' #submitsearch');

    select_tenant.bind("change", function(this: any,e) {
      _this.getDataForProgramId(panelId);
      _this.getDataForDepartamentoId(panelId);
      _this.getDataForOrganisationPositionId(panelId);
    });

    btn.bind("click", function(this: any, e){
      const table = $(tableHolderId).DataTable();

      const min = moment($(select_fechadesde).val()).unix();
      const max = moment($(select_fechahasta).val()).unix();
      if(!(isNaN(min) || isNaN(max))) {
        var total = min + '-delimiter-' + max;
        table.columns('fecha_quiz:name').search(total)
      }

      table.columns('departamentoid:name').search((select_department.val()).join(","));
      table.columns('id_curso:name').search((select_course.val()).join(","));
      table.columns('grupoid:name').search((select_grupo.val()).join(","));
      table.columns('tenantid:name').search((select_tenant.val()).join(","));
      table.columns('programid:name').search((select_program.val()).join(","));
      table.columns('suspended:name').search(select_suspended.val());
      table.columns('estado_grupo:name').search(select_estado_grupo.val());
      table.columns('cedula:name').search(input_cedula.val());

      table.draw();
    });
  }
  /**
   * Construct table using Datatables.net API
   *  multiple types supported
   * timeseries_to_rows (column 0 = timestamp)
   * timeseries_to_columns
   * timeseries_aggregations - column 0 is the metric name (series name, not a timestamp)
   * annotations - specific headers for this
   * table
   * json (raw)
   * columns[x].type === "date" then set columndefs to parse the date, otherwise leave it as default
   * convert table.columns[N].text to columns formatted to datatables.net format
   * @return {[Boolean]} True if loaded without errors
   */
  render() {
    const _this = this;
    const tableHolderId = '#datatable-panel-table-' + this.panel.id;
    try {
      if ($.fn.dataTable.isDataTable(tableHolderId)) {
        const aDT = $(tableHolderId).DataTable();
        aDT.destroy();
        $(tableHolderId).empty();
      }
    } catch (err) {
      // @ts-ignore
      console.log('Exception: ' + err.message);
    }
    this.getDataForSelect(this.panel.id);

    if (this.panel.emptyData) {
      //return;
    }
    const columns = [];
    const columnDefs = [];

    let rowNumberOffset = 0;
    if (this.panel.rowNumbersEnabled) {
      rowNumberOffset = 1;
      columns.push({
        title: '',
        type: 'number',
      });
      columnDefs.push({
        searchable: false,
        orderable: false,
        targets: 0,
        width: '1%',
      });
    }
    for (let i = 0; i < this.table.columns.length; i++) {
      const columnAlias = this.getColumnAlias(this.table.columns[i].text);
      const columnIndex = this.table.rows[0][i];
      const columnWidthHint = this.getColumnWidthHint(this.table.columns[i].text);
      var columnClassName = '';

      // column type "date" is very limited, and overrides our formatting
      // best to use our format, then the "raw" epoch time as the sort ordering field
      // https://datatables.net/reference/option/columns.type
      let columnType = this.table.columns[i].type;
      if (columnType === 'date') {
        columnType = 'num';
      }
      if (columnType === 'num' && this.panel.alignNumbersToRightEnabled) {
        columnClassName = 'dt-right'; // any reason not to align numbers right?
      }
      // TODO: add alignment options
      if (columnType === 'string') {
        columnClassName = 'dt-right';
      }
      // NOTE: the width below is a "hint" and will be overridden as needed, this lets most tables show timestamps
      // with full width
      /* jshint loopfunc: true */
      columns.push({
        title: columnAlias,
        type: columnType,
        width: columnWidthHint,
        className: columnClassName,
        data: columnIndex,
        name: columnIndex,
        render: function ( data: any, type: any, row: any, meta: any ) {
          return _this.getColumnRenderStyle(data, columnIndex);
        }
      });
      columnDefs.push({
        targets: i + rowNumberOffset,
        data: function(row: any, type: any, val: any, meta: any) {
          if (type === undefined) {
            return null;
          }
          const idx = meta.col;
          // sort/filter/type use raw
          let returnValue = row[idx].raw;
          if (type === 'display') {
            returnValue = row[idx].display;
          }
          return returnValue;
        },
        render: function(data: any, type: any, val: any, meta: any) {
          if (type === undefined) {
            return null;
          }
          const idx = meta.col;
          if (type === 'type') {
            return val[idx];
          }
          // sort/filter use raw
          let returnValue = val[idx].raw;
          if (type === 'display') {
            returnValue = val[idx].display;
          }
          return returnValue;
        },
        createdCell: (td: any, cellData: any, rowData: any, row: any, col: any) => {
          // orthogonal sort requires getting cell data differently
          const formattedData = $(td).html();
          // can only evaluate thresholds on a numerical value
          // also - hidden columns have null data
          if (!isNumber(_this.table.rows[row][col])) {
            return;
          }
          if (formattedData === null) {
            return;
          }
          cellData = formattedData;
          // set the fontsize for the cell
          $(td).css('font-size', _this.panel.fontSize);
          // undefined types should have numerical data, any others are already formatted
          let actualColumn = col;
          if (_this.panel.rowNumbersEnabled) {
            actualColumn -= 1;
          }
          // for coloring rows, get the "worst" threshold
          let rowColor = null;
          let color = null;
          let rowColorIndex = null;
          let rowColorData = null;
          if (_this.colorState.row) {
            // run all of the rowData through threshold check, get the "highest" index
            // and use that for the entire row
            if (rowData === null) {
              return;
            }
            rowColorIndex = -1;
            rowColorData = null;
            rowColor = _this.colorState.row;
            // this should be configurable...
            color = 'white';
            for (let columnNumber = 0; columnNumber < _this.table.columns.length; columnNumber++) {
              // only columns of type undefined are checked
              if (_this.table.columns[columnNumber].type === undefined) {
                rowColorData = _this.getCellColors(
                  _this.colorState,
                  columnNumber,
                  rowData[columnNumber + rowNumberOffset]
                );
                if (!rowColorData) {
                  continue;
                }
                if (rowColorData.bgColorIndex !== null) {
                  if (rowColorData.bgColorIndex > rowColorIndex) {
                    rowColorIndex = rowColorData.bgColorIndex;
                    rowColor = rowColorData.bgColor;
                  }
                }
              }
            }
            // style the entire row (the parent of the td is the tr)
            // this will color the rowNumber and Timestamp also
            $(td.parentNode)
              .children()
              .css('color', color);
            $(td.parentNode)
              .children()
              .css('background-color', rowColor);
          }

          if (_this.colorState.rowcolumn) {
            // run all of the rowData through threshold check, get the "highest" index
            // and use that for the entire row
            if (rowData === null) {
              return;
            }
            rowColorIndex = -1;
            rowColorData = null;
            rowColor = _this.colorState.rowcolumn;
            // this should be configurable...
            color = 'white';
            for (let columnNumber = 0; columnNumber < _this.table.columns.length; columnNumber++) {
              // only columns of type undefined are checked
              if (_this.table.columns[columnNumber].type === undefined) {
                rowColorData = _this.getCellColors(
                  _this.colorState,
                  columnNumber,
                  rowData[columnNumber + rowNumberOffset]
                );
                if (!rowColorData) {
                  continue;
                }
                if (rowColorData.bgColorIndex !== null) {
                  if (rowColorData.bgColorIndex > rowColorIndex) {
                    rowColorIndex = rowColorData.bgColorIndex;
                    rowColor = rowColorData.bgColor;
                  }
                }
              }
            }
            // style the rowNumber and Timestamp column
            // the cell colors will be determined in the next phase
            if (_this.table.columns[0].type !== undefined) {
              const children = $(td.parentNode).children();
              let aChild = children[0];
              $(aChild).css('color', color);
              $(aChild).css('background-color', rowColor);
              // the 0 column contains the row number, if they are enabled
              // then the above just filled in the color for the row number,
              // now take care of the timestamp
              if (_this.panel.rowNumbersEnabled) {
                aChild = children[1];
                $(aChild).css('color', color);
                $(aChild).css('background-color', rowColor);
              }
            }
          }

          // Process cell coloring
          // Two scenarios:
          //    1) Cell coloring is enabled, the above row color is skipped
          //    2) RowColumn is enabled, the above row color is process, but we also
          //    set the cell colors individually
          const colorData = _this.getCellColors(_this.colorState, actualColumn, cellData);
          if (!colorData) {
            return;
          }
          if (_this.colorState.cell || _this.colorState.rowcolumn) {
            if (colorData.color !== undefined) {
              $(td).css('color', colorData.color);
            }
            if (colorData.bgColor !== undefined) {
              $(td).css('background-color', colorData.bgColor);
            }
          } else if (_this.colorState.value) {
            if (colorData.color !== undefined) {
              $(td).css('color', colorData.color);
            }
          }
        },
      });
    }

    try {
      let shouldDestroy = false;
      if ($.fn.dataTable.isDataTable('#datatable-panel-table-' + this.panel.id)) {
        shouldDestroy = true;
      }
      if (shouldDestroy) {
        const destroyedDT = $('#datatable-panel-table-' + this.panel.id).DataTable();
        destroyedDT.destroy();
        $('#datatable-panel-table-' + this.panel.id).empty();
      }
    } catch (err) {
      // @ts-ignore
      console.log('Exception: ' + err.message);
    }

    // pass the formatted rows into the datatable
    //const formattedData = this.generateFormattedData(this.table.rows);

    if (this.panel.rowNumbersEnabled) {
      // shift the data to the right
    }
    let panelHeight = this.panel.panelHeight;
    if (this.panel.scroll) {
      if (typeof this.panel.height === 'undefined') {
        panelHeight = this.getGridHeight(this.panel.gridPos.h);
      }
    } else {
      panelHeight = '';
    }
    const orderSetting = this.panel.sortByColumnsData;

    /*let selectSettings: DataTables.SelectSettings;
    selectSettings = {
      style: 'os',
      items: 'single',
      info: true
    };*/
    var uid = _this.panel.targets[0].datasource.uid;
    var id = _this.dataSourceSrv.datasources[uid].id;
    let url = "/api/datasources/" + id + "/resources/data";
    const table = $(tableHolderId).DataTable({
      lengthMenu: [
        [20, 40],
        [20, 40],
      ],
      searching: _this.panel.searchEnabled,
      info: _this.panel.infoEnabled,
      lengthChange: _this.panel.lengthChangeEnabled,
      scrollCollapse: false,
      scrollX: true,
      scrollY: panelHeight,
      stateSave: false,
      dom: 'rtilBp',
      buttons: [
        {
          text: 'Descargar en CSV',
          action: function ( e, dt, node, config ) {
            var uid = _this.panel.targets[0].datasource.uid;
            var id = _this.dataSourceSrv.datasources[uid].id;
            let url = "/api/datasources/" + id + "/resources/download-handler?";
            url = url + $.param(dt.ajax.params());
            const win = window.open(url, '_blank');
            if (win) {
              win.focus();
            } else {
              alert('Por favor habilite las ventanas emergentes');
            }
          }
        }
      ],
      select: true,
      search: {
        regex: true,
        smart: false,
      },
      order: orderSetting,
      paging: !_this.panel.scroll,
      pagingType: _this.panel.datatablePagingType,
      processing: true,
      serverSide: true,
      columns: [
        { data: 'cedula', name: 'cedula', title: 'CEDULA' },
        { data: 'nombre', name: 'nombre', title: 'NOMBRE' },
        { data: 'telefono', name: 'telefono', title: 'TELEFONO' },
        { data: 'email', name: 'email', title: 'EMAIL' },
        { data: 'cargo', name: 'cargo', title: 'CARGO' },
        { data: 'regional', name: 'regional', title: 'REGIONAL' },
        { data: 'ciudad', name: 'ciudad', title: 'CIUDAD' },
        { data: 'area', name: 'area', title: 'AREA' },
        { data: 'cliente', name: 'cliente', title: 'CLIENTE' },
        { data: 'jefe_inmediato', name: 'jefe_inmediato', title: 'NOMBRE DEL JEFE INMEDIATO'},
        { data: 'correo_jefe_inmediato', name: 'correo_jefe_inmediato', title: 'CORREO ELECTRONICO DEL JEFE INMEDIATO'},
        { data: 'telefono_jefe', name: 'telefono_jefe', title: 'TELEFONO JEFE'},
        { data: 'canal', name: 'canal', title: 'CANAL'},
        { data: 'subcanal', name: 'subcanal', title: 'SUBCANAL'},
        { data: 'catchup_nombre', name: 'catchup_nombre', title: 'NOMBRE DEL CATCHUP'},
        { data: 'catchup_email', name: 'catchup_email', title: 'CORREO ELECTRONICO CATCHUP'},
        { data: 'timecreated', name: 'timecreated', title: 'FECHA DE CREACION DEL USUARIO'},
        { data: 'timesuspended', name: 'timesuspended', title: 'FECHA SUSPENSION DEL USUARIO'},
        { data: 'activo', name: 'activo', title: 'ESTADO_PLATAFORMA'},
        { data: 'lastaccess', name: 'lastaccess', title: 'ULTIMA FECHA DE INGRESO A LA PLATAFORMA'},
        { data: 'numero_ingresos', name: 'numero_ingresos', title: 'NUMERO DE INGRESOS'},
        { data: 'ultimo_ingreso_curso', name: 'ultimo_ingreso_curso', title: 'ULTIMO INGRESO AL CURSO'},
        { data: 'organizacion', name: 'organizacion', title: 'ORGANIZACION'},
        { data: 'programa', name: 'programa', title: 'NOMBRE PROGRAMA'},
        { data: 'departamento', name: 'departamento', title: 'DEPARTAMENTO'},
        { data: 'departamentoid', name: 'departamentoid', title: 'DEPARTAMENTOID'},
        { data: 'id_grupo', name: 'id_grupo', title: 'ID_GRUPO'},
        { data: 'grupoid', name: 'grupoid', title: 'GRUPOID'},
        { data: 'agrupacion', name: 'agrupacion', title: 'AGRUPACION'},
        { data: 'fecha_habilitacion', name: 'fecha_habilitacion', title: 'FECHA HABILITACION'},
        { data: 'fecha_deshabilitacion', name: 'fecha_deshabilitacion', title: 'FECHA DESHABILITACION'},
        { data: 'estado_grupo', name: 'estado_grupo', title: 'ESTADO_GRUPO'},
        { data: 'satisfaccion_promedio_total', name: 'satisfaccion_promedio_total', title: 'SATISFACCION PROMEDIO TOTAL'},
        { data: 'id_curso', name: 'id_curso', title: 'ID_CURSO'},
        { data: 'nombre_capacitacion', name: 'nombre_capacitacion', title: 'NOMBRE DE LA CAPACITACION'},
        { data: 'fecha_completado', name: 'fecha_completado', title: 'FECHA COMPLETADO'},
        { data: 'fecha_quiz', name: 'fecha_quiz', title: 'FECHA QUIZ'},
        { data: 'transferencia_total', name: 'transferencia_total', title: 'TRANSFERENCIA PROMEDIO TOTAL'},
        { data: 'intentos', name: 'intentos', title: 'INTENTOS'},
        { data: 'poblacion', name: 'poblacion', title: 'POBLACION'},
        { data: 'pais', name: 'pais', title: 'PAIS'},
        { data: 'ot', name: 'ot', title: 'OT'},
        { data: 'nit', name: 'nit', title: 'NIT'},
        { data: 'tenantid', name: 'tenantid', title: 'TENANTID'},
        { data: 'programid', name: 'programid', title: 'PROGRAMID'},
        { data: 'suspended', name: 'suspended', title: 'SUSPENSION'},
        { data: 'estado_manipulacion', name: 'estado_manipulacion', title: 'ESTADO MANIPULACION'},
        { data: 'fecha_inicio_asignacion_programa', name: 'fecha_inicio_asignacion_programa', title: 'FECHA INICIO ASIGNACION PROGRAMA'},
        { data: 'fecha_final_asignacion_programa', name: 'fecha_final_asignacion_programa', title: 'FECHA FINAL ASIGNACION PROGRAMA'},
        { data: 'campo1', name: 'campo1', title: 'CAMPO 1'},
        { data: 'campo2', name: 'campo2', title: 'CAMPO 2'},
        { data: 'campo3', name: 'campo3', title: 'CAMPO 3'},
      ],
      deferRender: true,
      ajax: {
        cache: true,
        async: true,
        url: url,
        type: 'GET',
        contentType: "application/json",
      }
    });

    table.on('select.dt', function(e, dt, type, indexes) {
      var data = dt.rows(indexes).data().flatten();
      var record = data[0];
      // @ts-ignore
      $('#rowInformationModal .modal-body').html(`
        <div class="container small-text">
          <div class="row">
            <div class="col-sm"><b>CEDULA:</b> ${record.cedula}</div>
            <div class="col-sm"><b>NOMBRE:</b> ${record.nombre}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>TELEFONO:</b> ${record.telefono}</div>
            <div class="col-sm"><b>EMAIL:</b> ${record.email}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>CARGO:</b> ${record.cargo}</div>
            <div class="col-sm"><b>REGIONAL:</b> ${record.regional}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>CIUDAD:</b> ${record.ciudad}</div>
            <div class="col-sm"><b>AREA:</b> ${record.area}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>CLIENTE:</b> ${record.cliente}</div>
            <div class="col-sm"><b>NOMBRE DEL JEFE INMEDIATO:</b> ${record.jefe_inmediato}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>CORREO ELECTRONICO DEL JEFE INMEDIATO:</b> ${record.correo_jefe_inmediato}</div>
            <div class="col-sm"><b>TELEFONO JEFE:</b> ${record.telefono_jefe}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>CANAL:</b> ${record.canal}</div>
            <div class="col-sm"><b>SUBCANAL:</b> ${record.subcanal}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>NOMBRE DEL CATCHUP:</b> ${record.catchup_nombre}</div>
            <div class="col-sm"><b>CORREO ELECTRONICO CATCHUP:</b> ${record.catchup_email}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>FECHA DE CREACION DEL USUARIO:</b> ${record.timecreated}</div>
            <div class="col-sm"><b>FECHA SUSPENSION DEL USUARIO:</b> ${record.timesuspended}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>ESTADO_PLATAFORMA:</b> ${record.activo}</div>
            <div class="col-sm"><b>ULTIMA FECHA DE INGRESO A LA PLATAFORMA:</b> ${record.lastaccess}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>NUMERO DE INGRESOS:</b> ${record.numero_ingresos}</div>
            <div class="col-sm"><b>ULTIMO INGRESO AL CURSO:</b> ${record.ultimo_ingreso_curso}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>ORGANIZACION:</b> ${record.organizacion}</div>
            <div class="col-sm"><b>NOMBRE PROGRAMA:</b> ${record.programa}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>DEPARTAMENTO:</b> ${record.departamento}</div>
            <div class="col-sm"><b>DEPARTAMENTOID:</b> ${record.departamentoid}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>ID_GRUPO:</b> ${record.id_grupo}</div>
            <div class="col-sm"><b>GRUPOID:</b> ${record.grupoid}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>AGRUPACION:</b> ${record.agrupacion}</div>
            <div class="col-sm"><b>FECHA HABILITACION:</b> ${record.fecha_habilitacion}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>FECHA DESHABILITACION:</b> ${record.fecha_deshabilitacion}</div>
            <div class="col-sm"><b>ESTADO_GRUPO:</b> ${record.estado_grupo}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>SATISFACCION PROMEDIO TOTAL:</b> ${record.satisfaccion_promedio_total}</div>
            <div class="col-sm"><b>ID_CURSO:</b> ${record.id_curso}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>NOMBRE DE LA CAPACITACION:</b> ${record.nombre_capacitacion}</div>
            <div class="col-sm"><b>FECHA COMPLETADO:</b> ${record.fecha_completado}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>FECHA QUIZ:</b> ${record.fecha_quiz}</div>
            <div class="col-sm"><b>TRANSFERENCIA PROMEDIO TOTAL:</b> ${record.transferencia_total}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>INTENTOS:</b> ${record.intentos}</div>
            <div class="col-sm"><b>POBLACION:</b> ${record.poblacion}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>PAIS:</b> ${record.pais}</div>
            <div class="col-sm"><b>OT:</b> ${record.ot}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>NIT:</b> ${record.nit}</div>
            <div class="col-sm"><b>TENANTID:</b> ${record.tenantid}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>PROGRAMID:</b> ${record.programid}</div>
            <div class="col-sm"><b>SUSPENSION:</b> ${record.suspended}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>ESTADO MANIPULACION:</b> ${record.estado_manipulacion}</div>
            <div class="col-sm"><b>FECHA INICIO ASIGNACION PROGRAMA:</b> ${record.fecha_inicio_asignacion_programa}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>FECHA FINAL ASIGNACION PROGRAMA:</b> ${record.fecha_final_asignacion_programa}</div>
            <div class="col-sm"><b>CAMPO 1:</b> ${record.campo1}</div>
          </div>
          <div class="row">
            <div class="col-sm"><b>CAMPO 2:</b> ${record.campo2}</div>
            <div class="col-sm"><b>CAMPO 3:</b> ${record.campo3}</div>
          </div>
        </div>
      `);

      // @ts-ignore
      $('#rowInformationModal').modal('show');
    });

    table.on('processing.dt', function(e, settings, processing) {
      if (processing) {
        // @ts-ignore
        $("#processingmodal").modal({
          backdrop: 'static',
          keyboard: false,
        });
      } else if($("#processingmodal").is(":visible")){
        // @ts-ignore
        $("#processingmodal").modal('hide');
      }
    });

    return;
  }


  getGridHeight(height: number) {
    const gridHeight = Math.ceil(height * this.GRID_CELL_HEIGHT) - this.TITLE_LINE_HEIGHT - 30;
    return gridHeight;
  }

  // For CSV Export
  render_values() {
    const rows = [];

    for (let y = 0; y < this.table.rows.length; y++) {
      const row = this.table.rows[y];
      const newRow = [];
      for (let i = 0; i < this.table.columns.length; i++) {
        newRow.push(this.formatColumnValue(i, y, row[i]));
      }
      rows.push(newRow);
    }
    return {
      columns: this.table.columns,
      rows: rows,
    };
  }

}

$(document).on('show.bs.modal', '.modal', function () {
  // @ts-ignore
  $(this).appendTo('body');
});
